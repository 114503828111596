import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [ "defaultOption", "option"]

  selectOption (e) {
    this.unselectExtraOptions()
    e.currentTarget.setAttribute('data-extended-radio-button-checked-param', !e.params.checked)
    if(e.params.checked) {
      this.defaultOptionTarget.click()
    }
  }

  unselectExtraOptions () {
    this.optionTargets.forEach((option) => {
      option.setAttribute('data-extended-radio-button-checked-param', false)
    })
  }
}
