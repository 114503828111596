import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  submitForm (event) {
    event.preventDefault()

    const groupingIndex = event.target.dataset.groupingIndex
    if (groupingIndex) {
      const intervalId = `delivery_restrictions_form[groupings][${groupingIndex}][shipping_interval_id]`
      const optionId = `delivery_restrictions_form[groupings][${groupingIndex}][shipping_option_id]`
      if (event.target.name === intervalId) {
        event
          .target
          .form
          .elements[optionId]
          .value = event.target.dataset.shippingOptionId
      }
    }

    const form = event.target.form
    form.requestSubmit()
  }

  submitFormOld (event) {
    event.preventDefault()

    if (event.target.name === 'delivery_restrictions_form[shipping_interval_id]') {
      event
        .target
        .form
        .elements['delivery_restrictions_form[shipping_option_id]']
        .value = event.target.dataset.shippingOptionId
    }

    const form = event.target.form
    form.requestSubmit()
  }
}
