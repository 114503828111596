import { Controller } from '@hotwired/stimulus'

export default class extends Controller {

  connect () {
    this.megaMenu = document.querySelector("#mega-menu")
    this.handleScroll = this.throttle(this.handleScroll.bind(this), 200)
    this.previousScrollPosition = window.scrollY
    window.addEventListener("scroll", this.handleScroll)
  }


  handleScroll () {
    if(window.scrollY > this.previousScrollPosition) {
      // user is scrolling down
      if(window.scrollY >= 80) {
        // user has scrolled more than the height of the mega menu - hide it
        this.megaMenu.classList.add('hide')
        this.element.classList.add('stick')
      }
    } else {
      // user is scrolling up - show the mega menu
      this.megaMenu.classList.remove('hide')
      this.element.classList.remove('stick')
    }

    this.previousScrollPosition = window.scrollY
  }

  throttle (fn, delay) {
    let waiting = false

    return function () {
      if(!waiting) {
        fn.apply(this, arguments)
        waiting = true
        setTimeout(() => (waiting = false), delay);
      }
    }
  }
}