import { Controller } from '@hotwired/stimulus'

// NOTE: a change was made on Turbo to NOT update 'permanent' elements from streams
// https://github.com/hotwired/turbo/pull/688
// There is further work to be able to bypass that restriction:
// https://github.com/hotwired/turbo/pull/622
// Until that is merged/released, we will use this controller to add the data-turbo-permanent to a turbo frame,
// so the frame can be updated via a turbo stream, and when it is rendered with a reference to this controller,
// when the controller connects, it will add the data-turbo-permanent attribute. This way we are able to update
// a frame and once updated is marked as permanent.
export default class extends Controller {
  connect() {
    this.element.dataset.turboPermanent = true
  }
}
