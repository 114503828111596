import { Controller } from '@hotwired/stimulus'

// Lets to move the scroll to an element specified by scrollTo property
export default class extends Controller {
  scroll (event) {
    event.preventDefault();

    const scroll_to_element = document.querySelector(event.target.dataset.scrollTo);
    scroll_to_element.scrollIntoView({ behavior: "smooth" });
  }
}
