import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static get targets () {
    return []
  }

  connect () {
    this.isDown = false;
    this.startX
    this.scrollLeft
  }

  mouseLeaveHandler() {
    this.element.classList.remove('drag-scroll-active')
    this.isDown = false
  }

  mouseDownHandler(e) {
    this.element.classList.add('drag-scroll-active')
    this.isDown = true
    this.startX = e.pageX - this.element.offsetLeft
    this.scrollLeft = this.element.scrollLeft
  }

  mouseMoveHandler (e) {
    if(!this.isDown) return
    e.preventDefault()
    this.x = e.pageX - this.element.offsetLeft
    // controls the speed of the scroll
    this.walk = (this.x - this.startX) * 1.25
    this.element.scrollLeft = this.scrollLeft - this.walk
  }
}
