import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [ "loaderLocation" ]

  connect() {
    // Given that this controller is going to be used mainly to show the loader after clicking on a link,
    // it adds the action to show loader when clicking on the link by default. So from the link element we
    // only need to add "data-controller='loader'" for the loader to show.
    if (this.element.nodeName == 'A') {
      // Avoid adding the action if the link already has it.
      if (this.element.dataset.action == undefined || !this.element.dataset.action.includes("loader#show")) {
        this.element.dataset.action = `${this.element.dataset.action || ""} click->loader#show`.trim()
      }
    }
  }

  show() {
    const element = this.elementWhereToDisplayLoader()
    if (!element.classList.contains("loader")) {
      element.classList.add("loader")
    }
  }

  hide() {
    const element = this.elementWhereToDisplayLoader()
    if (element.classList.contains("loader")) {
      element.classList.remove("loader")
    }
  }

  elementWhereToDisplayLoader() {
    // If the location where to display the loader is not provided, then we take the source element.
    if (this.hasLoaderLocationTarget) {
      return this.loaderLocationTarget
    } else {
      return this.element
    }
  }
}
