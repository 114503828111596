import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static get targets() {
    return ['sizeRadio']
  }

  selectSize(event) {
    const newSizeRadio = event.currentTarget
    const selectedSizeRadio = this.sizeRadioTargets.find(this.selectedSize)
    if (newSizeRadio === selectedSizeRadio) {
      return;
    }

    // remove .selected from parent <label>
    this.sizeRadioTargets.forEach((sizeRadio) => {
      if (sizeRadio !== newSizeRadio) {
        sizeRadio.closest('label').classList.remove('selected')
      }
    })
    newSizeRadio.closest('label').classList.add('selected')

    newSizeRadio.closest('form').requestSubmit();
  }

  selectedSize(sizeRadio) {
    return sizeRadio.closest('label').classList.contains('selected')
  }
}
