import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['slide', 'container']

  changeSlide(event) {
    if (event.productPageInitialRender === true)
    {
      return
    }

    var container = this.containerTarget
    this.slideTargets.some(function (slideItem) {
      if (slideItem.dataset.slideId == event.currentTarget.dataset.slideId) {
        container.scrollTo({ left: slideItem.offsetLeft, behavior: "smooth" })
        return true
      }
    });
  }
}
