import { Controller } from '@hotwired/stimulus'
import EmblaCarousel from 'embla-carousel'

export default class extends Controller {
  static get targets () {
    return ['container', 'nextButton', 'previousButton']
  }

  connect () {
    // Carousel parameters
    this.options = {
      loop: false,
      containScroll: 'trimSnaps',
      slidesToScroll: 1,
      align: 'start',
      dragFree: true
    }

    // initialize carousel
    this.carousel = EmblaCarousel(this.element, this.options)
    this.carousel.on('init', this.handleNavigationButtons.bind(this))
    this.carousel.on('select', this.handleNavigationButtons.bind(this))
  }


  previousSlide () {
    this.carousel.scrollPrev()
  }

  nextSlide () {
    this.carousel.scrollNext()
  }

  // If navigation is present, this toggles the prev/next button display when they reach the end/begining of carousel.
  handleNavigationButtons () {
    if (this.hasNextButtonTarget && this.hasPreviousButtonTarget) {
      this.carousel.canScrollPrev() ? this.previousButtonTarget.classList.remove('hidden') : this.previousButtonTarget.classList.add('hidden')
      this.carousel.canScrollNext() ? this.nextButtonTarget.classList.remove('hidden') : this.nextButtonTarget.classList.add('hidden')
    }
  }
}
