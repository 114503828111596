import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['titleInput', 'subTitleInput', 'title', 'subTitle']

  connect() {
    this.updateTitle()
    this.updateSubTitle()
  }

  updateTitle() {
    this.titleTarget.innerHTML = this.titleInputTarget.value
  }

  updateSubTitle() {
    this.subTitleTarget.innerHTML = this.subTitleInputTarget.value
  }
}
