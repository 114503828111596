import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [ 'fieldToClear' ]

  clearFields(event) {
    this.fieldToClearTargets.forEach((field) => {
      field.value = ''
    })
  }
}
