import { Controller } from '@hotwired/stimulus'

const hideDeliverySlotClass = 'account-subscriptions__delivery-information__times--hidden'

export default class extends Controller {
  static targets = ["deliverySlot"]

  toggleDependencies (event) {
    this.hideAllDeliverySlots()

    // extract date string from selected delivery date option
    this.selectedDate = event.target.htmlFor.match(/(\d{1,4}([.\-/])\d{1,2}([.\-/])\d{1,4})/g).toString()
    this.displayAvailableTimeSlots(this.selectedDate)
  }

  hideAllDeliverySlots () {
    this.deliverySlotTargets.forEach(slot => {
      slot.classList.add(hideDeliverySlotClass)
      slot.querySelector('input[type=radio]').checked = false
    })
  }

  displayAvailableTimeSlots (selectedDate) {
    // keep track of how many available slots there are
    this.availableDeliverySlots = []

    this.deliverySlotTargets.forEach((slot) => {
      if (slot.dataset.relatedDeliveryDate == selectedDate) {
        slot.classList.remove(hideDeliverySlotClass)
        this.availableDeliverySlots.push(slot)
      }
    })

    // If there is only one available slot, check it's radio input by default
    if (this.availableDeliverySlots.length == 1) {
      this.availableDeliverySlots[0].querySelector('input[type=radio]').checked = true
    }
  }
}
