import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  connect() {
    if (this.element.dataset['submitOnSelection'] == 'true') {
      this.element.addEventListener("change", this.submitForm);
    }

    // By default search is enabled so if no 'searchEnabled' is provided then we show the searchbox.
    const searchEnabled = this.element.dataset['searchEnabled'] != 'false'
    const shouldSort = this.element.dataset['shouldSort'] == 'true'

    import('choices.js')
      .then(({ default: Choices }) => {
        const choices = new Choices(
          this.element,
          {
            itemSelectText: '',
            searchEnabled: searchEnabled,
            shouldSort: shouldSort,
            callbackOnInit: () => {
              // Add a data attribute to the select element to indicate that the choices instance has been initialized
              this.element.dataset.choicesInitialized = true
            }
          }
        )
      })
      .catch((error) => 'An error occurred while loading Choices')
  }

  disconnect() {
    this.element.removeEventListener("change", this.submitForm);
  }

  submitForm(event) {
    const form = event.target.closest('form')
    form.requestSubmit()
  }
}
