import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static get targets () {
    return ['imageContainer']
  }

  hideImage() {
    if(this.hasImageContainerTarget) {
      this.imageContainerTarget.classList.add("delivery-card-form__default-image-container--hide")
    }
  }

  showImage() {
    if(this.hasImageContainerTarget) {
      this.imageContainerTarget.classList.remove("delivery-card-form__default-image-container--hide")
    }
  }
}
