import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static get targets () {
    return ['accordion']
  }

  connect () {
    this.updateAccordion()
    this.initialViewportWidth = window.innerWidth
  }

  handleResize () {
    this.updateAccordion()
  }

  updateAccordion () {
    if (window.innerWidth > 576) {
      this.accordionTarget.open = true
    } else {
      this.accordionTarget.open = false
    }
  }
}
