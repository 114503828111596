export default function sendEvents(rawData) {
  if (rawData !== undefined && rawData.length > 0) {
    const data = JSON.parse(rawData)
    if (data.gtag && (typeof gtag === 'function')) {
      if (Array.isArray(data.gtag)) {
        data.gtag.forEach(entry => { gtag('event', entry.action, entry.data) })
      } else {
        gtag('event', data.gtag.action, data.gtag.data)
      }
    }

    function sendPixelEvent(entry, requestId) {
      let eventId = entry.data['event_id']
      // Update request_id part of the eventId with the current request id, substituting
      // the request_id of when the event data was generated, so the events can be correctly
      // deduplicated in the trackers.
      let eventIdParts = eventId.split('_')
      eventIdParts[0] = requestId
      eventId = eventIdParts.join('_')
      delete entry.data['event_id']
      const customEvent = entry.data['custom_event']
      delete entry.data['custom_event']
      const trackTag = customEvent ? 'trackCustom' : 'track'
      fbq(trackTag, entry.action, entry.data, { eventID: eventId })
    }

    if (data.fbq && (typeof fbq === 'function')) {
      if (Array.isArray(data.fbq)) {
        data.fbq.forEach(entry => {
          sendPixelEvent(entry, data.requestId)
        })
      } else {
        sendPixelEvent(data.fbq, data.requestId)
      }
    }
    if (data.pintrk && (typeof pintrk === 'function')) {
      if (Array.isArray(data.pintrk)) {
        data.pintrk.forEach(entry => { pintrk('track', entry.action, entry.data) })
      } else {
        pintrk('track', data.pintrk.action, data.pintrk.data)
      }
    }

    function sendCriteoEvent(entry, deviceType) {
      const criteoEvents = [
        { event: 'setAccount', account: entry.data.account_id },
        { event: 'setSiteType', type: deviceType }
      ]
      if (entry.data.hashed_email) {
        criteoEvents.push({ event: 'setEmail', email: entry.data.hashed_email, hash_method: 'sha256' })
      }
      if (entry.data.zip_code) {
        criteoEvents.push({ event: 'setZipcode', zipcode: entry.data.zip_code })
      }
      criteoEvents.push({ event: entry.action, item: entry.data.item_details })
      window.criteo_q.push(criteoEvents)
    }

    if (data.criteo && typeof window.Criteo === 'object') {
      window.criteo_q = window.criteo_q || [];
      var deviceType = /iPad/.test(navigator.userAgent) ? 't' : (/Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Silk/.test(navigator.userAgent) ? 'm' : 'd')
      if (Array.isArray(data.criteo)) {
        data.criteo.forEach(entry => {
          sendCriteoEvent(entry, deviceType)
        })
      } else {
        sendCriteoEvent(data.criteo, deviceType)
      }
    }
  }
}
