import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  connect () {
    this.selectedCategory = document.querySelector('.selected')
    if(this.selectedCategory) {
      this.selectedCategory.scrollIntoView({
        inline: 'center'
      })
      document.body.scrollTop = document.documentElement.scrollTop = 0;
    }
  }
}
