import { Controller } from '@hotwired/stimulus'

// Lets to display hidden elements
export default class extends Controller {
  static targets = [ "hiddenField" ]

  connect () {
    // Hide fields initially
    this.hiddenFieldTargets.forEach(field => field.setAttribute('hidden', 'hidden'));
  }

  show (event) {
    if (event.currentTarget.dataset.preventDefault == 'true') {
      // In some scenarios we might want to prevent the event to bubble,
      // for example if it is clicking a link what fires the action we don't want to navigate to the link href.
      // But in other scenarios, like when clicking a radiobutton or checkbox, we want those elements
      // to bubble the event so their checked state changes.
      event.preventDefault();
    }

    // Show hidden elements
    this.hiddenFieldTargets.forEach(field => field.removeAttribute('hidden'));

    // Hide the button/link which fires the action of showing the hidden fields.
    if (event.currentTarget.dataset.hideTarget == 'true') {
       // if an element has a flexbox display then the hidden does not work, so we must remove the display
      event.currentTarget.style.display = 'none';
      event.currentTarget.setAttribute('hidden', 'hidden')
    }
  }
}
