import { Controller } from "@hotwired/stimulus"
import debounce from "lodash.debounce"

export default class extends Controller {
  static get targets () {
    return ["input", "hidden", "results"]
  }

  static get values () {
    return {
      submitOnEnter: Boolean,
      url: String,
      minLength: Number
    }
  }

  connect() {
    this.resultsTarget.hidden = true

    this.inputTarget.setAttribute("spellcheck", "false")

    this.mouseDown = false

    this.input = debounce(this.input.bind(this), 300)

    if (typeof this.inputTarget.getAttribute("autofocus") === "string") {
      this.inputTarget.focus()
    }
  }

  sibling(next) {
    const options = Array.from(
      this.resultsTarget.querySelectorAll(
        '[role="option"]:not([aria-disabled])'
      )
    )
    const selected = this.resultsTarget.querySelector('[aria-selected="true"]')
    const index = options.indexOf(selected)
    const sibling = next ? options[index + 1] : options[index - 1]
    const def = next ? options[0] : options[options.length - 1]
    return sibling || def
  }

  select(target) {
    for (const el of this.resultsTarget.querySelectorAll(
      '[aria-selected="true"]'
    )) {
      el.removeAttribute("aria-selected")
      el.classList.remove("active")
    }
    target.setAttribute("aria-selected", "true")
    target.classList.add("active")
    this.inputTarget.setAttribute("aria-activedescendant", target.id)
    target.scrollIntoView(false)
  }

  keyDown(event) {
    switch (event.key) {
      case "Escape":
        if (!this.resultsTarget.hidden) {
          this.hideAndRemoveOptions()
          event.stopPropagation()
          event.preventDefault()
        }
        break
      case "ArrowDown":
        {
          const item = this.sibling(true)
          if (item) this.select(item)
          event.preventDefault()
        }
        break
      case "ArrowUp":
        {
          const item = this.sibling(false)
          if (item) this.select(item)
          event.preventDefault()
        }
        break
      case "Tab":
        {
          const selected = this.resultsTarget.querySelector(
            '[aria-selected="true"]'
          )
          if (selected) {
            this.commit(selected)
          }
        }
        break
      case "Enter":
        {
          const selected = this.resultsTarget.querySelector(
            '[aria-selected="true"]'
          )
          if (selected && !this.resultsTarget.hidden) {
            this.commit(selected)
            if (!this.hasSubmitOnEnterValue) {
              event.preventDefault()
            }
          }
        }
        break
    }
  }

  blur() {
    if (this.mouseDown) return
    this.inputTarget.classList.remove('zip-code-selector-open')
    this.resultsTarget.hidden = true
  }

  commit(selected) {
    if (selected.getAttribute("aria-disabled") === "true") return

    if (selected instanceof HTMLAnchorElement) {
      selected.click()
      this.resultsTarget.hidden = true
      return
    }

    const textValue = this.extractTextValue(selected)
    const value = selected.getAttribute("data-autocomplete-value") || textValue
    this.inputTarget.value = textValue

    if (this.hasHiddenTarget) {
      this.hiddenTarget.value = value
      this.hiddenTarget.dispatchEvent(new Event("input"))
      this.hiddenTarget.dispatchEvent(new Event("change"))
    } else {
      this.inputTarget.value = value
    }

    this.inputTarget.focus()
    this.hideAndRemoveOptions()

    if (this.inputTarget.dataset.submitOnSelection == 'true') {
      const form = this.element.closest('form')
      form.requestSubmit()
    }
  }

  resultsClick(event) {
    if (!(event.target instanceof Element)) return
    const selected = event.target.closest('[role="option"]')
    if (selected) this.commit(selected)
    this.inputTarget.classList.remove('zip-code-selector-open')

    this.updatePopUpContent()
  }

  resultsMouseDown() {
    this.mouseDown = true
    this.resultsTarget.addEventListener(
      "mouseup",
      () => (this.mouseDown = false),
      { once: true }
    )
  }

  updatePopUpContent() {
    const popUp = document.querySelector('.pop-up-content')

    if (popUp) {
      popUp.classList.remove('pop-up-content__fixed-content')
    }
  }

  input() {
    this.element.removeAttribute("value")
    this.fetchResults()
  }

  identifyOptions() {
    let id = 0
    for (const el of this.resultsTarget.querySelectorAll(
      '[role="option"]:not([id])'
    )) {
      el.id = `${this.resultsTarget.id}-option-${id++}`
    }
  }

  hideAndRemoveOptions() {
    this.resultsTarget.hidden = true
    this.resultsTarget.innerHTML = null
    this.inputTarget.classList.remove('zip-code-selector-open')
    this.updatePopUpContent()
  }

  fetchResults() {
    const query = this.inputTarget.value.trim()
    if (!query || query.length < this.minLengthValue) {
      this.hideAndRemoveOptions()
      return
    }

    if (!this.hasUrlValue) return

    const headers = { "X-Requested-With": "XMLHttpRequest", "Accept": "text/html" }
    const url = new URL(this.urlValue, window.location.href)
    const params = new URLSearchParams(url.search.slice(1))
    params.append("q", query)
    url.search = params.toString()

    fetch(url.toString(), { headers })
      .then(response => response.text())
      .then(html => {
        this.resultsTarget.innerHTML = html
        this.identifyOptions()
        const options = this.resultsTarget.querySelectorAll('[role="option"]')
        if (options && options.length == 1 && options[0].innerHTML.toLowerCase() === query.toLowerCase()) {
          // If there is only one option and its text is exactly the same as the query, then select it.
          // This helps with the browser autocomplete feature, so selecting a browser saved value automatically
          // selects it in the input.
          this.commit(options[0])
        } else {
          const hasResults = options && options.length > 0
          this.resultsTarget.hidden = !hasResults
        }
      })
    this.inputTarget.classList.add('zip-code-selector-open')
    this.updatePopUpContent()
  }

  open() {
    if (!this.resultsTarget.hidden) return
    this.resultsTarget.hidden = false
    this.element.setAttribute("aria-expanded", "true")
  }

  close() {
    if (this.resultsTarget.hidden) return
    this.resultsTarget.hidden = true
    this.inputTarget.removeAttribute("aria-activedescendant")
    this.element.setAttribute("aria-expanded", "false")
    this.inputTarget.classList.remove('zip-code-selector-open')
    this.updatePopUpContent()
  }

  extractTextValue(el) {
    return el.hasAttribute("data-autocomplete-label")
      ? el.getAttribute("data-autocomplete-label")
      : el.textContent.trim()
  }
}
