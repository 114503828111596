/* NOTE: do not modify this file manually. Use online_store:asset_builder:update_auto_importers task from main app */ 

import { application } from './stimulus_app'
import accordion from './controllers/accordion_controller.js'
application.register('accordion', accordion)
import carousel from '../components/online_store/carousel/carousel_controller.js'
application.register('carousel', carousel)
import categories_base from './controllers/store/categories_base_controller.js'
application.register('categories-base', categories_base)
import categories_navigation from '../views/online_store/store/categories/categories_navigation/categories_navigation_controller.js'
application.register('categories-navigation', categories_navigation)
import categories_subnav from './controllers/categories_subnav_controller.js'
application.register('categories-subnav', categories_subnav)
import character_counter from './controllers/character_counter_controller.js'
application.register('character-counter', character_counter)
import choices_select_input from '../forms/online_store/inputs/choices_select/choices_select_input_controller.js'
application.register('choices-select-input', choices_select_input)
import clear_field_before_submit from './controllers/clear_field_before_submit_controller.js'
application.register('clear-field-before-submit', clear_field_before_submit)
import confirm from './controllers/confirm_controller.js'
application.register('confirm', confirm)
import country_selector from '../components/online_store/country_selector/country_selector_controller.js'
application.register('country-selector', country_selector)
import cropper from './controllers/store/checkout/cropper_controller.js'
application.register('cropper', cropper)
import data_privacy_consents from './controllers/data_privacy_consents_controller.js'
application.register('data-privacy-consents', data_privacy_consents)
import deliveries from './controllers/store/deliveries_controller.js'
application.register('deliveries', deliveries)
import drag_to_scroll from './controllers/drag_to_scroll_controller.js'
application.register('drag-to-scroll', drag_to_scroll)
import exclusive_selection from './controllers/exclusive_selection_controller.js'
application.register('exclusive-selection', exclusive_selection)
import extended_radio_button from './controllers/extended_radio_button_controller.js'
application.register('extended-radio-button', extended_radio_button)
import flash_notification from '../components/online_store/flash_notification/flash_notification_controller.js'
application.register('flash-notification', flash_notification)
import footer_links from '../components/online_store/footer_links/footer_links_controller.js'
application.register('footer-links', footer_links)
import form from './controllers/form_controller.js'
application.register('form', form)
import gift_cards from './controllers/store/gift_cards_controller.js'
application.register('gift-cards', gift_cards)
import input_mask from './controllers/input_mask_controller.js'
application.register('input-mask', input_mask)
import js_trackers from './controllers/store/js_trackers_controller.js'
application.register('js-trackers', js_trackers)
import js_trackers_on_view_port from './controllers/store/js_trackers_on_view_port_controller.js'
application.register('js-trackers-on-view-port', js_trackers_on_view_port)
import loader from './controllers/loader_controller.js'
application.register('loader', loader)
import mega_menu from './controllers/mega_menu_controller.js'
application.register('mega-menu', mega_menu)
import message_card from './controllers/message_card_controller.js'
application.register('message-card', message_card)
import newsletter_popup from './controllers/newsletter_popup_controller.js'
application.register('newsletter-popup', newsletter_popup)
import phone_number_input from '../forms/online_store/inputs/phone_number/phone_number_input_controller.js'
application.register('phone-number-input', phone_number_input)
import pop_up_remover from '../components/online_store/pop_up/pop_up_remover_controller.js'
application.register('pop-up-remover', pop_up_remover)
import product_card_carousel from '../components/online_store/product_card_carousel/product_card_carousel_controller.js'
application.register('product-card-carousel', product_card_carousel)
import product_carousel from '../components/online_store/product_carousel/product_carousel_controller.js'
application.register('product-carousel', product_carousel)
import remove_breaklines from './controllers/remove_breaklines_controller.js'
application.register('remove-breaklines', remove_breaklines)
import same_day_delivery_timer from '../components/online_store/same_day_delivery_timer/same_day_delivery_timer_controller.js'
application.register('same-day-delivery-timer', same_day_delivery_timer)
import scroll_into_view from './controllers/scroll_into_view_controller.js'
application.register('scroll-into-view', scroll_into_view)
import show_hidden_fields from './controllers/show_hidden_fields_controller.js'
application.register('show-hidden-fields', show_hidden_fields)
import sidebar from '../views/online_store/store/categories/sidebar/sidebar_controller.js'
application.register('sidebar', sidebar)
import size_selector from '../components/online_store/product_page/size_selector/size_selector_controller.js'
application.register('size-selector', size_selector)
import slides from './controllers/slides_controller.js'
application.register('slides', slides)
import sticker_message from './controllers/sticker_message_controller.js'
application.register('sticker-message', sticker_message)
import stripe_checkout from './controllers/store/checkout/stripe_checkout_controller.js'
application.register('stripe-checkout', stripe_checkout)
import subscription_date_slot_select from './controllers/subscription_date_slot_select_controller.js'
application.register('subscription-date-slot-select', subscription_date_slot_select)
import toggle_visibility from './controllers/toggle_visibility_controller.js'
application.register('toggle-visibility', toggle_visibility)
import truncated_text from '../components/online_store/truncated_text/truncated_text_controller.js'
application.register('truncated-text', truncated_text)
import turbo_stream_permanent_updater from './controllers/turbo_stream_permanent_updater_controller.js'
application.register('turbo-stream-permanent-updater', turbo_stream_permanent_updater)
import update_frame from './controllers/update_frame_controller.js'
application.register('update-frame', update_frame)
import variant_selector from '../components/online_store/product_page/variant_selector/variant_selector_controller.js'
application.register('variant-selector', variant_selector)
import zip_code_input from '../forms/online_store/inputs/zip_code/zip_code_input_controller.js'
application.register('zip-code-input', zip_code_input)
import zip_date_occasion_bar from '../views/online_store/home/zip_date_occasion_bar/zip_date_occasion_bar_controller.js'
application.register('zip-date-occasion-bar', zip_date_occasion_bar)
