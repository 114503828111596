import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = { hideLoader: String, disableValidation: String }

  connect() {
    if (this.disableValidationValue !== 'true') {
      this.element.dataset.action += ' submit->form#preventSubmitIfInvalid'
      this.element.dataset.action += ' turbo:submit-start->form#preventSubmitIfInvalid'
    }

    // Avoid adding the actions if the element already has them.
    if (this.element.dataset.action == undefined || !this.element.dataset.action.includes("form#disableSubmit")) {
      this.element.dataset.action =
        `${this.element.dataset.action || ""}
        submit->form#disableSubmit
        turbo:submit-start->form#disableSubmit
        turbo:submit-end->form#enableSubmit`.trim()
    }
  }

  disableSubmit() {
    if (this.submitPrevented) {
      return
    }

    this.element.querySelectorAll('button[type=submit], input[type=submit]').forEach(item => {
      item.disabled = true
    })

    if (!this.hideLoaderValue && !this.element.classList.contains("loader")) {
      this.element.classList.add("loader")
    }
  }

  enableSubmit() {
    this.element.querySelectorAll('button[type=submit], input[type=submit]').forEach(item => {
      item.disabled = false
    })

    if (this.element.classList.contains("loader")) {
      this.element.classList.remove("loader")
    }
  }

  validateInput(event) {
    const input = event.currentTarget;

    if (input.classList.contains('required')) {
      this.validateRequiredInput(input);
    }
  }

  preventSubmitIfInvalid(event) {
    if (this.isFormValid()) {
      this.submitPrevented = false
      return
    }

    this.submitPrevented = true
    event.preventDefault()
    event.stopImmediatePropagation()
  }

  isFormValid() {
    var requiredInputs = this.element.querySelectorAll('input.required, textarea.required');
    requiredInputs = Array.from(requiredInputs).filter((input) =>  {
      return this.isVisible(input);
    })

    const validationResults = requiredInputs.map((input) => {
      return this.validateRequiredInput(input);
    })

    if (!validationResults.length) {
      return true;
    }

    return !validationResults.includes(false)
  }

  isVisible(element) {
    return !!(element.offsetWidth || element.offsetHeight || element.getClientRects().length);
  }

  validateRequiredInput(input) {
    const inputValue = input.value.trim()
    let errorMessage = ''

    if (inputValue.length < input.dataset.minLength && input.dataset.minLengthErrorMessage) {
      if (errorMessage === '') {
        errorMessage = input.dataset.minLengthErrorMessage
      }
    }

    if (!inputValue.length && this.element.dataset.requiredErrorMessage) {
      if (errorMessage === '') {
        errorMessage = this.element.dataset.requiredErrorMessage
      }
    }

    const error = input.parentNode.querySelector('.error')

    if (errorMessage !== '') {
      if (error) {
        // Update error
        error.innerText = errorMessage
      } else {
        this.addErrorMessage(input, errorMessage)
      }

      return false
    } else {
      this.removeError(input, error)

      return true
    }
  }

  addErrorMessage(input, errorMessage) {
    // Add error
    input.classList.remove('validInput')
    input.classList.add('invalidInput')
    input.parentNode.classList.add('field_with_errors')

    const errorElement = document.createElement('div');

    errorElement.classList.add('error');
    errorElement.appendChild(document.createTextNode(errorMessage));
    input.parentNode.append(errorElement);
  }

  removeError(input, error) {
    if (!input.classList.contains('validInput')) {
      // Remove error
      input.classList.remove('invalidInput');
      input.parentNode.classList.remove('field_with_errors')
      input.classList.add('validInput');

      if (error) {
        error.remove()
      }
    }
  }
}
