import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static get targets () {
    return ['select', 'prefix', 'field']
  }

  connect() {
    import('choices.js')
      .then(({ default: Choices }) => {
        const choices = new Choices(this.selectTarget, {
          itemSelectText: '',
          shouldSort: false,
          shouldSortItems: false,
          sorter: function () {
            return 0
          },
          callbackOnCreateTemplates: function(template) {
            return {
              item: (classNames, data) => {
                return template(`
                  <div class="${classNames.item} ${
                  data.highlighted
                    ? classNames.highlightedState
                    : classNames.itemSelectable
                } ${
                  data.placeholder ? classNames.placeholder : ''
                }" data-item data-id="${data.id}" data-value="${data.value}" ${
                  data.active ? 'aria-selected="true"' : ''
                } ${data.disabled ? 'aria-disabled="true"' : ''}>
                    <span class="fi fi-${JSON.parse(data.customProperties).alpha2}"></span> ${data.label}
                  </div>
                `);
              },
              choice: (classNames, data) => {
                return template(`
                  <div class="${classNames.item} ${classNames.itemChoice} ${
                  data.disabled ? classNames.itemDisabled : classNames.itemSelectable
                }" data-select-text="${this.config.itemSelectText}" data-choice ${
                  data.disabled
                    ? 'data-choice-disabled aria-disabled="true"'
                    : 'data-choice-selectable'
                } data-id="${data.id}" data-value="${data.value}" ${
                  data.groupId > 0 ? 'role="treeitem"' : 'role="option"'
                }>
                    <span class="fi fi-${JSON.parse(data.customProperties).alpha2}"></span> ${data.label}
                  </div>
                `);
              },
            };
          },
        });

        choices.passedElement.element.addEventListener(
          'choice',
          (event) => {
            this.prefixTarget.innerHTML = `+${JSON.parse(event.detail.choice.customProperties).code}`

            // Update phone min-length depending on the selected country
            let prevMinLength = this.fieldTarget.dataset['minLength']
            let availableMinLengths = JSON.parse(this.fieldTarget.dataset['availableMinLengths'])
            let country = event.detail.choice.value
            let newMinLength = availableMinLengths[country]
            this.fieldTarget.dataset['minLengthErrorMessage'] = this.fieldTarget.dataset['minLengthErrorMessage'].replace(` ${prevMinLength}`, ` ${newMinLength}`)
            this.fieldTarget.dataset['minLength'] = newMinLength
          },
          false,
        );
      })
      .catch((error) => 'An error occurred while loading Choices')
  }

  focusField() {
    this.fieldTarget.focus()
  }
}
