import { Controller } from '@hotwired/stimulus'

// Removes and prevents adding breaklines to text inputs.
export default class extends Controller {
  prevent (e) {
    if (e.keyCode === 13) {
      e.preventDefault()
      return false
    }
  }

  remove (e) {
    // setTimeout to wait for having the text pasted in the target
    setTimeout(function () {
      const pastedText = e.target.value
      const pastedTextWithoutLineBreaks = pastedText.replace(/[\r\n]+/g, ' ')
      e.target.value = pastedTextWithoutLineBreaks
    }, 0)
  }
}
