import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  connect () {
    this.ticking = false
    this._boundScrollHandler = this.onScroll.bind(this)

    if (!document.querySelector('.data-privacy-options-bar')) {
      this.addScrollListener()
    }

    // Trigger initial scroll check
    document.dispatchEvent(new CustomEvent('scroll'))
  }

  disconnect () {
    this.removeScrollListener()
  }

  addScrollListener () {
    document.addEventListener('scroll', this._boundScrollHandler, { passive: true })
  }

  removeScrollListener () {
    document.removeEventListener('scroll', this._boundScrollHandler)
  }

  onScroll () {
    if (!this.ticking) {
      window.requestAnimationFrame(() => {
        const scrollY = window.scrollY || document.documentElement.scrollTop
    
        if ((scrollY / this.documentHeight()) > this.scrollThreshold()) {
          this.showPopup()
          this.removeScrollListener()
        }
    
        this.ticking = false
      })
    
      this.ticking = true
    }
  }

  scrollThreshold () {
    if (this._scrollThreshold === undefined) {
      const desktop = this.data.get('dThreshold') || 0.25
      const mobile = this.data.get('mThreshold') || 0.25
      this._scrollThreshold = window.innerWidth < 768 ? mobile : desktop
    }
    return this._scrollThreshold
  }

  documentHeight () {
    if (!this._documentHeight) {
      this._documentHeight = document.documentElement.scrollHeight
    }
    return this._documentHeight
  }

  showPopup () {
    this.element.classList.add('newsletter-popup--open')
  }
}