// Ios <= 12 doesn't support intersection observer, so for the time being we are using a polyfill. Turbo also relies on this module, but is not flexible for a dynamic import.
// We can remove this import once we no longer need to support ios <= 12
import 'intersection-observer'
import 'lazysizes'
import '@hotwired/turbo-rails'
import "./auto_importer"

// Handles images that aren't properly rendered on ajax request in safari
if (navigator.userAgent.match(/iPad/i) || navigator.userAgent.match(/iPhone/i) || navigator.userAgent.match(/Version\/[\d.]+.*Safari/)) {
  document.addEventListener('turbo:before-stream-render', reloadImages)
  document.addEventListener('turbo:before-render', reloadImages)
}

function reloadImages () {
  setTimeout(() => {
    document.querySelectorAll('img').forEach((img)=> {
      if (img.loading != 'lazy' && img.hasAttribute('srcset')) {
        img.outerHTML = img.outerHTML;
      }
    })
  }, 50)
}
