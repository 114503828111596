import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static get targets () {
    return []
  }

  selectAll(e) {
    const parentDetails = e.target.closest("details")
    if (!parentDetails) return;

    const nestedCheckboxes = parentDetails.querySelectorAll(
      "details input[type='checkbox']"
    )

    nestedCheckboxes.forEach((checkbox) => {
      checkbox.checked = e.target.checked;
    })
  }
  
  toggleView(e) {
    const parentDetails = e.target.closest("details")
    if(parentDetails.open) {
      e.target.innerHTML = "+"
    } else {
      e.target.innerHTML = "-"
    }
  }
}
