import { Controller } from '@hotwired/stimulus'

// Lets to toggle visibility of elements
export default class extends Controller {
  static targets = [ "toggler", "toToggle" ]

  connect () {
    if (this.hasTogglerTarget) {
      // If the rendered page has a toggler then update elements according to its checked value.
      // If togggler is initially hidden for some reason, then there is nothing to do.
      this.toggle();
    }
  }

  toggle (event) {
    this.toToggleTargets.forEach(toToggleTarget => {
      if (this.togglerTarget.checked) {
        toToggleTarget.removeAttribute('hidden');
        this.disableSubmits(toToggleTarget, false);
      } else {
        toToggleTarget.setAttribute('hidden', 'hidden');
        this.disableSubmits(toToggleTarget, true);
      }
    })
  }

  // Disables/Enables the submit child elements
  disableSubmits (element, disableValue) {
    if (element.type === 'submit') {
      element.disabled = disableValue;
    }
    Array.from(element.children).forEach(child => {
      this.disableSubmits(child, disableValue);
    });
  }
}
