import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static get targets () {
    return [
      'defaultVariantRadio',
      'variantRadio',
      'addToCartButton',
      'stickerMessageSection',
      'stickerMessageUnavailable',
      'stickerMessageFormControl',
      'stickerMessageCheckbox',
      'stickerMessageLearnMoreLink',
      'stickerMessageModifyLink',
      'fixedCtaContainer',
      'variantSelectorObserver'
    ]
  }
  static values = {
    productPageInitialRender: Boolean,
    editStickerMessageUrl: String,
    stickerMessageLearnMoreUrl: String,
    unselectStickerMessageUrl: String
  }

  connect () {
    const preCheckedVariantRadio = this.variantRadioTargets.find(variantRadio => variantRadio.checked)
    if (preCheckedVariantRadio) {
      const fakeClickEvent = new Event('click')
      fakeClickEvent.productPageInitialRender = this.productPageInitialRenderValue
      preCheckedVariantRadio.dispatchEvent(fakeClickEvent)

      const fakeChangeEvent = new Event('change')
      fakeChangeEvent.productPageInitialRender = this.productPageInitialRenderValue
      preCheckedVariantRadio.dispatchEvent(fakeChangeEvent)
    }

    this.showCorrectStickerMessageLink()

    this.observer = new IntersectionObserver(this.handleIntersection.bind(this), {
      root: null,
      rootMargin: '0px',
      threshold: 1.0
    })
    this.observer.observe(this.variantSelectorObserverTarget)
  }

  handleIntersection(entries) {
    entries.forEach(entry => {
      if(entry.intersectionRatio < 1) {
        this.fixedCtaContainerTarget.classList.add('fixed')
      } else {
        this.fixedCtaContainerTarget.classList.remove('fixed')
      }
    })
  }

  selectVariant(event) {
    const currentVariantRadio = event.currentTarget
    // remove .selected from parent
    this.variantRadioTargets.forEach((variantRadio) => {
      if (variantRadio !== currentVariantRadio) {
        variantRadio.closest('.product-variant-selection__variant').classList.remove('product-variant-selection__variant--selected')
      }
    })
    // If a variant should already be selected on page load - highlight the variant and center it in the view
    this.selectedVariant = currentVariantRadio.closest('.product-variant-selection__variant')
    if (this.selectedVariant) {
      this.selectedVariant.classList.add('product-variant-selection__variant--selected')
    }

    // Current variant selected
    if (currentVariantRadio.checked) {
      this.selectedVariantId = currentVariantRadio.value
      this.toggleAddToCartButton(currentVariantRadio)
      this.addVariantIdToProductInfoFrame()
      this.updatedVariantIdInSizeSelector()
      this.updateStickerMessageSection(currentVariantRadio)
    } else { // Current variant deselected
      // Now addToCartButton depends on default variant
      this.selectedVariantId = this.defaultVariantRadioTarget.value
      this.toggleAddToCartButton(this.defaultVariantRadioTarget)
      this.removeVariantIdFromProductInfoFrame()
      this.updateStickerMessageSection(this.defaultVariantRadioTarget)
    }
  }

  toggleAddToCartButton(variantRadio) {
    if (variantRadio.dataset.variantOrderable === 'false') {
      this.addToCartButtonTarget.disabled = true
    } else {
      this.addToCartButtonTarget.disabled = false
    }
  }

  addVariantIdToProductInfoFrame() {
    // Add selected variant_id to product-info-frame srcForRefresh, so when the frame is refreshed
    // it will keep the variant selection.
    const productInfoFrame = document.getElementById("product-info-frame")
    if (productInfoFrame == null) {
      return;
    }

    productInfoFrame.dataset.srcForRefresh = this.addSelectedVariantIdToUrl(productInfoFrame.dataset.srcForRefresh)
  }

  removeVariantIdFromProductInfoFrame() {
    // Remove variant_id from product-info-frame srcForRefresh, so when the frame is refreshed
    // it will keep the variant deselection.
    const productInfoFrame = document.getElementById("product-info-frame")
    if (productInfoFrame == null) {
      return;
    }

    const url = productInfoFrame.dataset.srcForRefresh.split('?')[0]
    productInfoFrame.dataset.srcForRefresh = url
  }

  updatedVariantIdInSizeSelector() {
    if (document.querySelectorAll("input[name='size']").length <= 1) {
      // If there is just one size then we don't show the size selector. Nothing to do.
      return;
    }

    const sizeSelectorVariantId = document.querySelector("input[type='hidden'][id='size_selector_variant_id']")
    sizeSelectorVariantId.value = this.selectedVariantId;
  }

  updateStickerMessageSection(currentVariantRadio) {
    if(!this.hasStickerMessageSectionTarget) {
      return
    }

    if (currentVariantRadio.dataset.variantStickerable === 'true') {
      this.stickerMessageCheckboxTarget.disabled = false
      this.stickerMessageFormControlTarget.classList.remove('disabled')
      this.stickerMessageUnavailableTarget.setAttribute('hidden', 'hidden')
      this.stickerMessageModifyLinkTarget.href = this.addSelectedVariantIdToUrl(this.editStickerMessageUrlValue)
      this.stickerMessageLearnMoreLinkTarget.href = this.replaceSelectedVariantIdInUrl(this.stickerMessageLearnMoreUrlValue)
    } else {
      this.stickerMessageCheckboxTarget.disabled = true
      this.stickerMessageUnavailableTarget.removeAttribute('hidden')
      this.stickerMessageFormControlTarget.classList.add('disabled')
    }
  }

  showStickerMessagePanel(e) {
    if (e.target.checked) {
      const frame = document.getElementById("right-side-panel");
      const editUrl = this.addSelectedVariantIdToUrl(this.editStickerMessageUrlValue)
      frame.setAttribute('src', editUrl);

      // when the checkbox is checked we show the panel to fill the details, but the checkbox only will have
      // checked state if the details are saved, otherwise, if the panel is closed without saving details
      // then the checkbox must not be checked. So at this point we don't know if it will be checked or not
      // and we set it to false
      e.target.checked = false
      // For some reason the checkbox keeps the "checked=checked" attribute even after setting it to false,
      // so we manually remove it here so the attribute can be used in css files
      e.target.removeAttribute("checked")
    } else {
      // For some reason the checkbox keeps the "checked=checked" attribute even after unchecking it,
      // so we manually remove it here so the attribute can be used in css files
      e.target.removeAttribute("checked")

      // Inform the sever that customer removed sticker message and refresh product info
      const frame = document.getElementById("product-info-frame");
      const unselectUrl = this.addSelectedVariantIdToUrl(this.unselectStickerMessageUrlValue)
      frame.setAttribute('src', unselectUrl);
    }
  }

  showCorrectStickerMessageLink() {
    if(!this.hasStickerMessageSectionTarget) {
      return
    }

    if(this.hasStickerMessageCheckboxTarget && this.stickerMessageCheckboxTarget.checked) {
      this.stickerMessageLearnMoreLinkTarget.setAttribute('hidden', 'hidden')
      this.stickerMessageModifyLinkTarget.removeAttribute('hidden')
    } else {
      this.stickerMessageLearnMoreLinkTarget.removeAttribute('hidden')
      this.stickerMessageModifyLinkTarget.setAttribute('hidden', 'hidden')
    }
  }

  preventClickIfDisabled(e) {
    if (this.stickerMessageCheckboxTarget.disabled) {
      e.preventDefault();
    }
  }

  // Used to replace the selected variant id url query params.
  addSelectedVariantIdToUrl(url) {
    const baseUrl = url.split('?')[0]
    const queryParams = url.split('?')[1]
    const queryString = new URLSearchParams(queryParams);
    queryString.set("variant_id", this.selectedVariantId);
    return baseUrl + "?" + queryString.toString()
  }

  // Used to replace the selected variant id in urls which uses it as a param of the url itself, not a query param.
  replaceSelectedVariantIdInUrl(url) {
    let segments = url.split('/');
    segments[segments.length - 1] = this.selectedVariantId;
    return segments.join('/');
  }
}
