import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static get targets () {
    return ['dateDisplay', 'calendar']
  }

  showCalendar() {
    this.calendarTarget.classList.add('show')
    document.body.classList.add('fixed-body')
  }

  closeCalendar(e) {
    const clickedElement = e.target;
    const isSelectableDate = clickedElement.dataset.availableDate === "true";
    const isCalendarBackground = clickedElement.dataset.zipDateOccasionBarTarget === "calendar";
    
    if (isSelectableDate || isCalendarBackground) {
      this.calendarTarget.classList.remove('show')
      document.body.classList.remove('fixed-body')
    }
  }

  setDate(e) {
    if (e.currentTarget.previousElementSibling?.classList.contains('disabled')) return;

    this.selectedDate = e.currentTarget.previousElementSibling.value    
    this.updateDateDisplay(this.selectedDate)
    this.closeCalendar(e)
    this.toogleSubmitButton()
  }

  updateDateDisplay(date) {
    this.dateDisplayTarget.value = this.formatDateForDisplay(date)
  }

  formatDateForDisplay(date) {
    const [year, month, day] = date.split("-");
    return `${day}/${month}/${year}`;
  }

  toogleSubmitButton() {
    const submitButton = document.querySelector('form#new_delivery_restrictions_form button[type="submit"]')

    const zipCode = document.querySelector('#delivery_restrictions_form_zip_code');
    const deliveryDate = document.querySelector('#delivery_restrictions_form_delivery_date');
    const occasion = document.querySelector('#occasion');

    if (zipCode.value && deliveryDate.value && occasion.value) {
      submitButton.removeAttribute('disabled')
    } else {
      submitButton.setAttribute('disabled', 'disabled')
    }
  }

  submitForm() {
    const form = document.getElementById('new_delivery_restrictions_form')
    if (form) {
      form.requestSubmit()
    }
  }

  clearForm(e) {
    e.preventDefault()
    document.getElementById('delivery_restrictions_form_zip_code').value = null
    document.getElementById('delivery_restrictions_form_zip_code_id').value = null
    this.dateDisplayTarget.value = null
    const radioButtons = document.getElementsByName("delivery_restrictions_form[delivery_date]");
    radioButtons.forEach(radio => {
      radio.checked = false;
    });
    this.submitForm()
  }
}
