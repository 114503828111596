import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static get targets () {
    return ['slide', 'container', 'nav', 'pagination']
  }

  connect() {
    let activeItem = this.slideTargets.find(target => target.classList.contains('active'))
    if (activeItem) {
      let activeIndex = activeItem.dataset.productCarouselIndex
      this.scrollToSlide(activeIndex)
    }

    let options = {
      root: this.element,
      rootMargin: '0px',
      threshold: [...Array(100).keys()].map(x => x / 100)
    }

    let observer = new IntersectionObserver((entries, observer) => {
      entries.forEach(entry => {
        if(entry.intersectionRatio>0.80){
          this.setActiveTarget(entry)
        }else{
          this.removeActiveTarget(entry)
        }
      });
    }, options);

    this.slideTargets.forEach(target => { observer.observe(target) });
  }

  setActiveTarget(entry) {
    entry.target.classList.add('active')
    this.navTargets.concat(this.paginationTargets).forEach(target => {
      if(target.dataset.productCarouselIndex == entry.target.dataset.productCarouselIndex){
        target.classList.add('active')
      }
    })
  }

  removeActiveTarget(entry){
    entry.target.classList.remove('active')
    this.navTargets.concat(this.paginationTargets).forEach(target => {
      if(target.dataset.productCarouselIndex == entry.target.dataset.productCarouselIndex){
        target.classList.remove('active')
      }
    })
  }


  changeSlide(event) {
    this.scrollToSlide(event.currentTarget.dataset.productCarouselIndex)
  }

  scrollToSlide(index) {
    let clickedThumbnailIndex = index

    this.slideTargets.forEach((item) => {
      if(item.dataset.productCarouselIndex == clickedThumbnailIndex){
        this.containerTarget.scrollTo({left: item.offsetLeft, behavior: "smooth" })
      }
    })
  }
}
