import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['select'];

  connect() {
    this.selectedCountry = this.getSelectedCountryFromCookies()
    // use choices.js library to customize select element
    import('choices.js')
      .then(({ default: Choices }) => {
        this.choices = new Choices(this.selectTarget, {
          searchEnabled: false,
          sorter: function () {
            return 0
          },
          callbackOnCreateTemplates: function(template) {
            return this.templateCustomization(template);
          }.bind(this)
        });

        this.setChoices();
      })
      .catch(error => {
        console.error('Failed to load Choices.js', error);
      });
  }

  setChoices() {
    // Once choices have been added, fill in values with French and Belgium flags, and set seletion based on this.selectedCountry
    if (this.choices) {
      this.choices.setChoices([
        { value: 'FR', label: 'France', selected: this.selectedCountry == 'FR', customProperties: { icon: 'fr' } },
        { value: 'BE', label: 'Belgique', selected: this.selectedCountry == 'BE', customProperties: { icon: 'be' } }
      ], 'value', 'label', false);
    } else {
      console.error('Choices instance is not initialized');
    }
  }

  templateCustomization(template) {
    return {
      // create choices.js select element
      item: (classNames, data) => {
        return template(`
          <div class="${classNames.item}" data-item data-id="${data.id}" data-value="${data.value}">
            <span class="fi fi-${data.value.toLowerCase()}"></span>${this.element.dataset.showCountryName == 'true' ? `<span>${data.label}</span>` : ''}
          </div>
        `);
      },
      // set choices.js select element options
      choice: (classNames, data) => {
        return template(`
          <div class="${classNames.item} ${classNames.itemChoice}" data-choice data-id="${data.id}" data-value="${data.value}">
            <span class="fi fi-${data.value.toLowerCase()}"></span>${this.element.dataset.showCountryName == 'true' ? `<span>${data.label}</span>` : ''}
          </div>
        `);
      }
    };
  }

  getSelectedCountryFromCookies() {
    // Create 2D array based on cookies string
    this.cookies = document.cookie.split(';').map((cookie) => cookie.split('='))
    // filter out the selected_country cookie
    this.selectedCountryCookie = this.cookies.filter((keyValue) => keyValue[0].trim() == 'selected_country')
    // if selected_country cookie is present
    if(this.selectedCountryCookie.length > 0) {
      // assign it as this.selectedCountry
      return this.selectedCountryCookie[0][1]
    } else {
      // set FR as the default
      return "FR"
    }
  }

  updateSelectedCountry() {
    // update cookie to selected country with an expiry date of 30 days.
    document.cookie = `selected_country=${this.selectTarget.value}; max-age=2592000;`
  }
}
