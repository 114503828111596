import { Controller } from '@hotwired/stimulus'
import SendEvents from '../../js_trackers_send_events'

// We will store the events data to be sent to trackers in this array
var eventsDataToSendToTrackers = []

// Responsible for sending info to js trackers.
// NOTE: the controller is connected to the BODY element => no need to add data-controller=.. within the body again.
export default class extends Controller {
  connect () {
    document.addEventListener('turbo:before-fetch-response', this.sendEventsToTrackers)
  }

  disconnect () {
    document.removeEventListener('turbo:before-fetch-response', this.sendEventsToTrackers)
  }

  // Used to update a trackers data to send when some option is clicked.
  // The data will be updated on the element found by id, passed on dataset.trackersUpdateSelector'.
  // Make sure it is unique.
  // The elements that trigger the action should have 'trackers-data' set in their data set,
  // as that is what is passed to the click target.
  // When clicking the target, the tracker data will be sent to the trackers via
  // data-action="click->js-trackers#send"
  updateDataToSend (event) {
    const clickTarget = document.querySelector(event.currentTarget.dataset.trackersUpdateSelector)
    if (clickTarget !== undefined && clickTarget !== null) {
      clickTarget.dataset.trackersDataForSend = event.currentTarget.dataset.trackersDataForUpdate
    }
  }

  // Used to send tracker 'action' events, such as 'add_to_cart' usually from clicks
  // Example: <a data-action="click->js-trackers#send" data-trackers-data-for-send="{ gtag: { action: 'add_to_cart', data: {...} } }"
  send (event) {
    if (event.productPageInitialRender === true) {
      return
    }

    // At this point we do not want to send the data to trackers because references
    // the request_id of when the data was built, but we want the data to reference the request_id
    // of the current request (the request generated due to the click action that called this event handler).
    // So we store the data to be sent at this point, and when the turbo frame response is done and the
    // `turbo:before-fetch-response` event is fired we will retrieve the request_id of current request
    // and we use it to replace the old request_id in the data, and then we send the data to trackers.
    // NOTE: This approach won't work properly if a user does the same action multiple times in a very short
    // time span (for example if a cart has the same variant 2 times and the user clicks on both removal links very fast)
    // because the `sendEventsToTrackers` will assign the same request_id for both although each removal action generated
    // its own request_id. But this scenario should be marginal.
    eventsDataToSendToTrackers.push(event.currentTarget.dataset.trackersDataForSend)
  }

  // When a new 'page' is loaded in a turbo frame.
  sendEventsToTrackers (event) {
    const headers = event.detail.fetchResponse.response.headers

    // sends data to trackers with the current request_id
    const requestId = headers.get('x-request-id')
    eventsDataToSendToTrackers.forEach((trackersDataForSend) => {
      let data = JSON.parse(trackersDataForSend)
      data.requestId = requestId
      SendEvents(JSON.stringify(data))
    });
    // clear it so we do not send the same event multiple times
    eventsDataToSendToTrackers = []

    // We want to tell trackers that the 'page' was 'viewed',
    // so we set headers['x-custom-page-view'] = lpath('xx')
    // in the controller and we use it here to send the page_view.
    if (headers.has('x-custom-page-view')) {
      const pagePath = headers.get('x-custom-page-view')
      // TODO: add fbq
      gtag('set', 'page_path', pagePath)
      gtag('event', 'page_view', { 'send_to': 'GA4' })
    }
  }

}
