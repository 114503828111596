import { Controller } from '@hotwired/stimulus'

// Basic implementation of Rails UJS's data-confirm functionality
export default class extends Controller {
  static values = {
    message: String
  }

  connect() {
    this.element.dataset.action += ' click->confirm#click'
  }

  click(event) {
    if (!this.messageValue.length) {
      return
    }

    if (confirm(this.messageValue)) {
      return
    } else {
      event.preventDefault()
    }
  }
}
