import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [ "remaining"]

  connect () {
    setInterval(() => {
      this.updateTimer()
    }, 30000)
  }

  updateTimer() {
    let cutoffTime = new Date(this.data.get("cutoff"))
    let cutoffTimeUTC = new Date(Date.UTC(cutoffTime.getUTCFullYear(), cutoffTime.getUTCMonth(),
    cutoffTime.getUTCDate(), cutoffTime.getUTCHours(),
    cutoffTime.getUTCMinutes(), cutoffTime.getUTCSeconds()))

    let currentTime = new Date()
    let currentTimeUTC = new Date(Date.UTC(currentTime.getUTCFullYear(), currentTime.getUTCMonth(),
    currentTime.getUTCDate(), currentTime.getUTCHours(),
    currentTime.getUTCMinutes(), currentTime.getUTCSeconds()))

    if (cutoffTimeUTC - currentTimeUTC > 60000) {
      let remainingTime = cutoffTimeUTC - currentTimeUTC
      let hours = Math.floor((remainingTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
      let minutes = Math.floor((remainingTime % (1000 * 60 * 60)) / (1000 * 60))
      let formattedDateTime = []
      if(hours > 0) {
        formattedDateTime.push(`${hours} h`)
      }
      if(minutes > 0) {
        formattedDateTime.push(`${minutes} min.`)
      }
      this.remainingTarget.innerHTML = formattedDateTime.join(' et ')
    } else {
      this.disconnect()
      this.element.remove()
    }
  }
}
