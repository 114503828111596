import { Controller } from '@hotwired/stimulus'
import SendEvents from '../../js_trackers_send_events'

// The purpose of this controller is to be able to send trackers 'action' events when an element enters the view port.
// This is specially important in item lists on mobile, where the customer might only see the first few items.
// If we were to send trackers that she viewed all of them, that would be false.
export default class extends Controller {
  connect () {
    const options = {
      root: null, // intersect with view port
      rootMargin: '0px',
      threshold: 0.75
    }
    const observer = new IntersectionObserver(this.intersectionCallback, options)
    observer.observe(this.element)
  }

  intersectionCallback = (entries) => {
    entries.forEach(entry => this.checkIntersection(entry))
  }

  checkIntersection (entry) {
    if (entry.isIntersecting && entry.intersectionRatio >= 0.75) {
      SendEvents(entry.target.dataset.trackersDataOnViewPort)
    }
  }
}
