import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  connect () {
    const mask = this.element.dataset.mask;

    import('imask')
      .then(({ default: IMask }) => {
        switch (mask) {
          case 'credit_card_number' : {
            IMask(this.element, {
              mask: '0000 0000 0000 0000'
            })
            break
          }
          case 'cvc': {
            IMask(this.element, {
              mask: '0000'
            })
            break
          }
          case 'mmyy': {
            IMask(this.element, {
              mask: '00/00'
            })
            break
          }
          case 'phone_number': {
            IMask(this.element, {
              mask: '000000000000'
            })
          }
        }
      })
      .catch((error) => 'An error occurred while loading imask')
  }
}
