import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ["shortText", "fullText", "toggleButton"]
  static values = {show: String, hide: String}

  toggle() {
    if(this.element.classList.contains('open')) {
      this.element.classList.remove('open')
      this.toggleButtonTarget.innerHTML = this.showValue
    } else {
      this.element.classList.add('open')
      this.toggleButtonTarget.innerHTML = this.hideValue
    }
  }
}