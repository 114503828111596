import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  connect () {
    document.body.classList.add('fixed-body')
  }

  disconnect () {
    document.body.classList.remove('fixed-body')
    this.refreshBasePageIfRequired();
  }

  removePopUp (event) {
    event.preventDefault();
    const bgRef = this.element.classList.contains('pop-up-bg') ? this.element : this.element.closest(".pop-up-content")
    const popUpContainer = bgRef.parentElement
    if (popUpContainer.nodeName.toLowerCase() === "turbo-frame") {
      // Turbo frames are updated when its src attribute is changed, because there is an observable
      // over that src attribute which triggers the update when it is changed.
      // When a link/button targets a frame, what clicking on it does is setting the src of that frame.
      // If the frame "src" does not change, then the observable doesn't fire and the pop up is not displayed
      // on subsequent clicks.
      //
      // To solve that issue, this code clears the "src" attribute, so the next time the link is clicked,
      // the attribute is set to the target and the request is fired.
      //
      // This issue could be solved in other ways like making this controller hide content instead of removing it,
      // and the opener link/button should show content again after clicking on it.
      // It could be also solved with turbo-stream with action=replace,
      // but we will end with a lot of open web sockets open for this interaction, what seems inapropiate.
      popUpContainer.src = '';
    }

    // Remove pop-up content
    popUpContainer.innerHTML = '';

    this.refreshBasePageIfRequired();
  }

  refreshBasePageIfRequired() {
    // Check if base page must be updated after the popup closed.
    // For example, if we are in category page and we open the cart summary popup and we modify
    // delivery restrictions or we remove an item, we need to refresh category page because
    // the previous display became obsolete after the changes.
    const refreshPageFrame = document.getElementById("refresh-base-page")
    if (refreshPageFrame && refreshPageFrame.dataset.refresh == 'true') {
      // The frames to be refreshed have the data attribute src-for-refresh, which indicates
      // where it will get the content from.
      document.querySelectorAll("turbo-frame[data-src-for-refresh]").forEach(frame => {
        // Refresh frame content.
        frame.src = '';
        frame.src = this.setParamsToUrl(frame.dataset.srcForRefresh, refreshPageFrame.dataset.urlParams);
      })

      // Once the frames have been refreshed, remove the data-refresh=true from the refresh-base-page frame,
      // so it won't keep refreshing them every time the popup is closed. If the frames must be refreshed again,
      // then the data-refresh=true will be set again by the store_controller#turbo_stream_refresh_base_page
      delete refreshPageFrame.dataset.refresh
      delete refreshPageFrame.dataset.urlParams
    }
  }

  setParamsToUrl(url, urlParamsToAdd) {
    if (urlParamsToAdd === undefined || urlParamsToAdd === null || urlParamsToAdd === "{}" || urlParamsToAdd === "") {
      return url;
    }

    const [path, queryString] = url.split("?")
    const urlParams = new URLSearchParams(queryString)

    const urlParamsToAddObj = JSON.parse(urlParamsToAdd);
    Object.entries(urlParamsToAddObj).forEach(([key, value]) => {
      urlParams.set(key, value);
    });

    return `${path}?${urlParams.toString()}`;
  }
}
