import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static get targets () {
    return ['zipcodeSearch', 'dateSelection', 'filters']
  }

  submitForm (event) {
    event.preventDefault()

    const form = event.target.form
    form.requestSubmit()
  }

  connect () {
    this.zipcodeIsSelected = this.zipcodeSearchTarget.querySelector('input').value != ''
    this.dateIsSelected = this.dateSelectionTarget.querySelector('input:checked') != null
    this.updateAccordion()
    this.initialViewportWidth = window.innerWidth
  }

  handleResize () {
    this.updateAccordion()
  }

  updateAccordion () {
    if (window.innerWidth > 576) {
      this.openAccordion(this.zipcodeSearchTarget)
      this.openAccordion(this.dateSelectionTarget)
      this.openAccordion(this.filtersTarget)

    } else {
      this.closeAccordion(this.filtersTarget)
      this.updateCurrentInput()
    }
  }

  updateCurrentInput () {
    if(this.zipcodeIsSelected) {
      this.closeAccordion(this.zipcodeSearchTarget)
      if(this.dateIsSelected) {
        this.closeAccordion(this.dateSelectionTarget)
      } else {
        this.openAccordion(this.dateSelectionTarget)
      }
    } else {
      this.openAccordion(this.zipcodeSearchTarget)
      this.closeAccordion(this.dateSelectionTarget)
    }
  }

  openAccordion (accordion) {
    accordion.open = true
  }

  closeAccordion (accordion) {
    accordion.open = false
  }
}
