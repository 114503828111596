import { Controller } from '@hotwired/stimulus'

let elements;
let stripe;
let returnUrl;
let form;
export default class extends Controller {
  static targets = ['form']

  static values = {
    stripeData: Object
  }

  connect () {
    console.log("Connecting")

    if (this.paymentElement) {
      this.paymentElement.mount()
    } else {
      this.refreshTimer = setInterval(this.initStripe.bind(this), 50)
    }
  }

  disconnect () {
    console.log("disconnect")
    stripe = undefined
    this.paymentElement.unmount()
  }

  formTargetConnected(element) {
    form = element
    form.addEventListener("submit", this.handleSubmit);
  }

  initStripe() {
    if(typeof(Stripe) === "undefined") {
      console.log("Not loaded yet")
      return
    } else {
      clearInterval(this.refreshTimer)
      this.mountCheckout()
    }
  }

  async mountCheckout() {
    if(stripe) {
      console.log("[Mount] Tried to mount but already mounted.")
      clearInterval(this.refreshTimer)
      return
    }
    if(typeof(Stripe) === "undefined") {
      console.log("[Mount] Not loaded yet")
      return
    }

    console.log("[Mount] Mounting Stripe...")


    const stripeData = this.stripeDataValue
    returnUrl = stripeData.return_url
    stripe = Stripe(stripeData.publishable_key)

    const appearance = {
      theme: 'stripe',
      variables: {
        borderRadius: '3px',
        spacingGridRow: '1.5rem'
      },
      rules: {
        '.Input': {
          boxShadow: '0px',
          padding: '1rem',
          borderColor: "#dedddb"
        },
        '.Input:focus': {
          boxShadow: '0px',
          borderColor: '#dedddb',
          outline: 'none'
        },
        '.Tab': {
          boxShadow: '0px',
          padding: '1rem',
        },
          '.Label': {
            fontSize: '0.75rem'
          }
      }
    };
    const clientSecret = stripeData.payment_intent_client_secret
    elements = stripe.elements({ appearance, clientSecret });

    const paymentElementOptions = {
      layout: "tabs",
      paymentMethodOrder: ['card', 'apple_pay', 'google_pay', 'paypal'],
    };
    this.paymentElement = elements.create("payment", paymentElementOptions);
    this.paymentElement.mount("#payment-element");
  }

  async handleSubmit(e) {
    const crm_no_payment_check = document.querySelector("#checkout_v3_payments_payment_form_provider_no_payment")
    if (crm_no_payment_check && crm_no_payment_check.checked === true) {
      // Do not prevent regular form submit because CRM no_payment must be processed as usual
      return;
    }

    e.preventDefault();

    const errorsDivClass = "payments-form__error"
    const errorsDivHiddenClass = `${errorsDivClass}--hidden`

    function showError(errorMessage) {
      const errorsDiv = findErrorsDiv();
      const errorReasonsUl = document.querySelector(`.${errorsDivClass}-reason`);

      if (errorsDiv.classList.contains(errorsDivHiddenClass)) {
        errorsDiv.classList.remove(errorsDivHiddenClass);
      }
      errorReasonsUl.innerHTML = `<li>${errorMessage}</li>`;

      form.classList.remove("loader");
      // Enable form submit button
      form.querySelector('button[type="submit"]').disabled = false
    }

    function hideErrors() {
      const errorsDiv = findErrorsDiv();
      if (!errorsDiv.classList.contains(errorsDivHiddenClass)) {
        errorsDiv.classList.add(errorsDivHiddenClass);
      }
    }

    function findErrorsDiv() {
      return document.querySelector(`.${errorsDivClass}`);
    }

    hideErrors()

    const formData = new FormData(form);
    formData.delete('authenticity_token');
    let urlParams = new URLSearchParams(formData)
    urlParams.append("stripe_payment_intent_id", document.querySelector("#stripe_payment_intent_id").value)
    // Add params for CRM usage
    const cartIdHiddenField = document.querySelector("#cart_id")
    if (cartIdHiddenField) {
      urlParams.append("cart_id", cartIdHiddenField.value)
    }
    const customerIdHiddenField = document.querySelector("#customer_id")
    if (customerIdHiddenField) {
      urlParams.append("customer_id", customerIdHiddenField.value)
    }
    const queryString = urlParams.toString();
    const canProceedWithCheckoutPath = "/payment-verification/stripe/can_proceed_with_checkout?" + queryString
    const response = await fetch(canProceedWithCheckoutPath, {
      method: "GET",
      headers: { Accept: "text/vnd.turbo-stream.html, text/html, application/xhtml+xml" }
    });

    // If there was an error the ajax call returns the turbo streams messages with the errors
    if (response.status !== 200) {
      const text = await response.text()
      Turbo.renderStreamMessage(text)
      return;
    }

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: returnUrl
      },
    });

    if (error) {
      let message;
      if (error.type === "card_error" || error.type === "validation_error") {
        message = error.message
      } else {
        message = "Une erreur inattendue est apparue." // "An unexpected error occurred."
      }

      showError(message);
    }
  }
}
