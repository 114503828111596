import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['fixedAmountOption', 'customAmountText']

  toggleSubmit (event) {
    const form = event.target.closest('form')
    const submit = form.querySelector('button[type=submit]')
    if (this.currentlySelectedOption() !== undefined) {
      submit.disabled = false
    } else {
      submit.disabled = true
    }
  }

  currentlySelectedOption () {
    let option;
    const fixedAmountOption = this.fixedAmountOptionTargets.find(option => option.checked)
    if (fixedAmountOption !== undefined) {
      return fixedAmountOption
    }
    if (this.customAmountTextTarget.value.length > 0) {
      return this.customAmountTextTarget
    }
    return undefined
  }
}
