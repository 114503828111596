import { Controller } from '@hotwired/stimulus'
import debounce from 'lodash.debounce'

export default class extends Controller {
  static targets = ['croppedField', 'image', 'input', 'modifiedField', 'zoom']
  static values = { modified: Boolean }

  connect() {
    this._debouncedUpdateCroppedImage = debounce(this.updateCroppedImage.bind(this), 300)
    this._initialized = false

    if (this.imageTarget.src != '') {
      this.initializeCropper()
      this._existingImage = true
    } else {
      this._existingImage = false
    }
  }

  disconnect() {
    this.imageTarget.removeEventListener('crop', this._debouncedUpdateCroppedImage)
  }

  handleUpload(event) {
    const files = event.target.files

    if (files && files.length > 0) {
      const file = files[0]

      if (!this.validateUpload(file)) { return }

      if (URL) {
        this.renderImage(URL.createObjectURL(file))
      } else if (FileReader) {
        reader = new FileReader();
        reader.onload = function () {
          this.renderImage(reader.result);
        };
        reader.readAsDataURL(file);
      }
    }
  }

  initializeCropper() {
    const _this = this

    import('cropperjs')
      .then(({ default: Cropper }) => {
        this._cropper = new Cropper(this.imageTarget, {
          aspectRatio: 1 / 1,
          background: false,
          center: false,
          checkCrossOrigin: false,
          cropBoxMovable: false,
          cropBoxResizable: false,
          dragMode: 'move',
          guides: false,
          viewMode: 1,
          zoomOnWheel: false,
          ready() {
            _this.updateRangeStart(this.cropper.initialCanvasData.height / this.cropper.initialCanvasData.naturalHeight)
            _this.updateCroppedImage(_this._existingImage)

            if (!_this._initialized) {
              // NOTE: We can't debounce using the Cropper shortcut option approach
              //       so we have to manually manage the listener, also we attach
              //       the listener after the cropper is ready to prevent marking
              //       the image as modified during the zoom in updateRangeStart().
              _this.imageTarget.addEventListener('crop', _this._debouncedUpdateCroppedImage)
              _this._initialized = true
            }
          }
        })
      })
      .catch((error) => 'An error occurred while loading Cropper')
  }

  renderImage(blob) {
    this.imageTarget.src = blob
    this._existingImage = false

    if (this._cropper === undefined) {
      this.initializeCropper()
    } else {
      this._cropper.replace(blob)
    }

    this.showCropper()
  }

  remove(event) {
    event.preventDefault()
    this.element.classList.remove('cropping');
    this.inputTarget.value = ''
    this.imageTarget.src = ''
    this.croppedFieldTarget.value = ''
    if (this._existingImage) {
      this.modifiedFieldTarget.value = '1'
    } else {
      this.modifiedFieldTarget.value = '0'
    }
  }

  rotateLeft(event) {
    event.preventDefault()
    this._cropper.rotate(-90)
    this.updateCroppedImage()
  }

  rotateRight(event) {
    event.preventDefault()
    this._cropper.rotate(90)
    this.updateCroppedImage()
  }

  showCropper() {
    this.element.classList.add('cropping')
  }

  updateCroppedImage(setNotModified) {
    this.modifiedFieldTarget.value = setNotModified == true ? '0' : '1'
    this.croppedFieldTarget.value = this._cropper.getCroppedCanvas({ height: 600, width: 600 }).toDataURL('image/jpeg')
  }

  updateRangeStart(minValue) {
    const start = (minValue * 0.8).toFixed(6) // toFixed mitigates precision errors
    const maxZoom = 4
    const max = start * maxZoom
    this._cropper.zoomTo(start)
    this.zoomTarget.step = (max - start) / 20
    this.zoomTarget.min = start
    this.zoomTarget.max = max + 0.001 // add a tiny bit to allow user to zoom to the max
    this.zoomTarget.value = start
  }

  validateUpload(file) {
    if (file.size > 4000000) {
      this.inputTarget.value = ''
      this.inputTarget.parentNode.classList.add('field_with_errors')
      this.inputTarget.classList.add('invalidInput')
      return false
    } else {
      this.inputTarget.parentNode.classList.remove('field_with_errors')
      this.inputTarget.classList.remove('invalidInput')
      return true
    }
  }

  zoom(event) {
    this._cropper.zoomTo(event.target.value)
  }
}
