import { Controller } from '@hotwired/stimulus'

// Lets to have a radio button group working with a free text option.
export default class extends Controller {
  selectFreeText (event) {
    const freeTextInput = event.target
    const radioButton = event.target.previousElementSibling

    // First uncheck other options
    const radioButtons = document.querySelectorAll(`[name='${radioButton.name}']`);
    radioButtons.forEach(radioButton => radioButton.checked = false);

    // Set input's border to green
    freeTextInput.classList.add('checked');

    // Check free text radio button and set the text value to the radio button
    // so there will be a value for the attribute in the params sent to the action.
    radioButton.checked = true;
    radioButton.value = event.target.value;
  }

  cleanFreeText(event) {
    const radioButtons = document.querySelectorAll(`[name='${event.target.name}']`);
    const freeTextRadioButton = Array.from(radioButtons).find(rb => rb.id.endsWith('free_text'));
    const freeTextInput = freeTextRadioButton.nextElementSibling;
    freeTextInput.value = '';
    freeTextInput.classList.remove('checked');
  }
}
