import { Controller } from '@hotwired/stimulus'

// Lets to update a turbo frame from js.
export default class extends Controller {
  static targets = ["frameToUpdate", "form"]
  
  update(event) {
    var form;
    if (this.hasFormTarget) {
      form = this.formTarget;
    } else {
      // This controller currently does not support updating frames located in different forms.
      form = this.frameToUpdateTargets[0]?.closest('form')
    }
    const formData = new FormData(form);
    formData.delete('authenticity_token');
    const queryString = new URLSearchParams(formData).toString();
    // Getting the path to update the frame from data attributes instead of from src because
    // using src in a frame makes it to lazy load the content, and most times we won't want that.
    // Also we are updating the src, so we will lose the original src unless we store it somewhere else.
    // So, if in the future we want reload a frame that is lazy loaded, we could do
    // frame.dataset.updateFramePath = frame.src if frame.dataset.updateFramePath.nil?
    // that way we could have the frame being lazy loaded and then updateFramePath will be filled from that src.
    this.frameToUpdateTargets.forEach((frame) => {
      let newSrc;
      if (frame.dataset.updateFramePath.includes("?")) {
        newSrc = frame.dataset.updateFramePath + "&" + queryString;
      } else {
        newSrc = frame.dataset.updateFramePath + "?" + queryString;
      }
      // Updating src does trigger a frame reload.
      frame.setAttribute('src', newSrc);
    })
  }
}
