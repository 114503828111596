import { Controller } from '@hotwired/stimulus'

// Updates character count of an input
export default class extends Controller {
  static targets = [ "counter"]

  connect () {
    const textInput = this.counterTarget.previousSibling;
    textInput.dataset["action"] = `${textInput.dataset["action"]} keyup->character-counter#updateCounter`.trim();
    this.updateCounterFromTextInput(textInput);
  }

  updateCounter (evt) {
    const textInput = evt.target;
    this.updateCounterFromTextInput(textInput)
  }

  updateCounterFromTextInput (textInput) {
    const messageLength = textInput.value.length;
    this.counterTarget.textContent = messageLength + '/' + textInput.maxLength;
  }
}
